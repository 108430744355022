import type { SVGProps } from 'react';
const SvgBigChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M18.177 22.258a.62.62 0 0 0 .27-.494c0-.18-.09-.36-.225-.494l-8.131-8.85 8.13-8.895a.67.67 0 0 0-.044-.988.67.67 0 0 0-.988.045l-8.626 9.344a.65.65 0 0 0 0 .943l8.626 9.344a.67.67 0 0 0 .988.045"
    />
  </svg>
);
export default SvgBigChevronLeft;
