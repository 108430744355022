/**
 * Adds radial background glow
 *
 * @example
 * <div className="relative min-h-full overflow-hidden">
 *   <GiftCardsBackgroundGlow/>
 * </div>
 */
export function GiftCardsBackgroundGlow() {
  return (
    <div className="pointer-events-none absolute left-[22.5vw] top-[-20vw] z-[-1] size-[55vw] rounded-full bg-campaignAccent/10 blur-[10vw] md:top-[-27vw] lg:top-[-35vw]"></div>
  );
}
