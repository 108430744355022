
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { CampaignDevices } from 'src/components/campaigns/campaign-devices';
import { CampaignFaqs } from 'src/components/campaigns/campaign-faqs';
import { CampaignLivesSlider, isContentContainerLiveConcertSlider, } from 'src/components/campaigns/campaign-lives-slider';
import { CampaignTabs } from 'src/components/campaigns/campaign-tabs';
import { CampaignTestimonials } from 'src/components/campaigns/campaign-testimonials';
import { GiftCardsAboutVinyl } from 'src/components/gift-cards/gift-cards-about-vinyl';
import { GiftCardsBackgroundGlow } from 'src/components/gift-cards/gift-cards-background-glow';
import { GiftCardsHeader } from 'src/components/gift-cards/gift-cards-header';
import { GiftCardsPurchaseHero } from 'src/components/gift-cards/gift-cards-purchase-hero';
import { GiftCardsSecondarySignup } from 'src/components/gift-cards/gift-cards-secondary-signup';
import HeadTags from 'src/components/head-tags';
import LandingPageFooter from 'src/components/landing-page/landing-page-footer';
import Page from 'src/components/page';
import { SeoFooter } from 'src/components/seo-footer';
import { useCuratedContent } from 'src/hooks/use-curated-content';
import { useGiftCards } from 'src/hooks/use-gift-cards';
import { CuratedContentSlug, ProductsStripeQuery, type CuratedContentQuery } from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { EmptyPageParameters } from 'src/types';
import { Faq, getFaqs } from 'src/utilities/get-faqs';
import { getCurrentLocale } from 'src/utilities/i18n-helpers';
import { type PagePropsWithSeo, serializeTranslationsToSeoSections } from 'src/utilities/seo-helpers';
import { incrementalServerSideWithQuery } from 'src/utilities/ssr-helpers';
type GiftCardsPageProps = {
    products: ProductsStripeQuery;
    curatedContentFallback: CuratedContentQuery;
    faqs: Faq[];
} & PagePropsWithSeo;
/**
 * Gift cards landing page
 */
function GiftCardsPage({ products, curatedContentFallback, faqs, seoSections }: GiftCardsPageProps) {
    const t = useTranslate();
    const { price, selectedOption, disableAnnualSubscription, onSubmit, onChangeOption } = useGiftCards({ products });
    const { data: curatedContentData } = useCuratedContent(CuratedContentSlug.Live, curatedContentFallback);
    // Find the first Live Concert slider
    const firstLiveConcertSlider = curatedContentData?.curatedContent.curatedContent?.find(isContentContainerLiveConcertSlider);
    return (<Page testId="gift-cards-page">
      <GiftCardsBackgroundGlow />
      <HeadTags title={t('gift_cards__page_title')} description={t('gift_cards__page_description')}/>
      <div className="mx-auto mb-10 flex max-w-[90rem] flex-col gap-y-16 px-5 md:gap-y-24 md:px-8">
        <GiftCardsPurchaseHero price={price} disableAnnualSubscription={disableAnnualSubscription} selectedOption={selectedOption} onChange={onChangeOption} onSubmit={onSubmit}/>
        <div className="space-y-16 md:space-y-24">
          <GiftCardsAboutVinyl />
          <div className="flex flex-col gap-y-20">
            <section>
              <CampaignTabs />
            </section>
            <CampaignDevices />
          </div>
          {firstLiveConcertSlider && <CampaignLivesSlider slider={firstLiveConcertSlider}/>}
          <div className="md:ml-16">
            <CampaignTestimonials />
          </div>
          <GiftCardsSecondarySignup />
          <CampaignFaqs faqs={faqs}/>
        </div>
      </div>
      <SeoFooter sections={seoSections}/>
    </Page>);
}
GiftCardsPage.headerComponent = GiftCardsHeader;
GiftCardsPage.footerComponent = LandingPageFooter;
export default GiftCardsPage;
const getStaticProps = incrementalServerSideWithQuery<EmptyPageParameters, GiftCardsPageProps>(async ({ sdk, context }) => {
    const products = await sdk.productsStripe();
    // Fetch the curated content for the page, it will be used as initial data
    // during the incremental server side rendering
    const curatedContentFallback = await sdk.curatedContent({ slug: CuratedContentSlug.Live });
    const currentLocale = getCurrentLocale(context.locale);
    const faqs = await getFaqs({ locale: currentLocale, zendeskCategoryId: '20000383032603' });
    // Fetch SEO articles to show in the footer
    const seoSections = await serializeTranslationsToSeoSections([
        'gift_cards__seo_section_1_content',
        'gift_cards__seo_section_2_content',
        'gift_cards__seo_section_3_content',
        'gift_cards__seo_section_4_content',
        'gift_cards__seo_section_5_content',
        'gift_cards__seo_section_6_content',
        'gift_cards__seo_section_7_content',
    ], context);
    return {
        props: { products, curatedContentFallback, faqs, seoSections },
        // Revalidate product list after one hour
        revalidate: 60 * 60
    };
});

    async function __Next_Translate__getStaticProps__193b17f3489__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/gift-cards',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193b17f3489__ as getStaticProps }
  