import { Fragment } from 'react';
import FavoriteIcon from '@stageplus/icons/react/favorite';
import Image from 'next/image';
import useTranslate from 'src/hooks/use-translate';
import type { TranslationKeyCommon } from 'src/types';
import { cloudinaryLoader } from 'src/utilities/image-sdk';

export type TestimonialProps = {
  /** Testimonial text. */
  text: TranslationKeyCommon;
  /** Date as free text. */
  date?: TranslationKeyCommon;
  /** Where the testimonial was published? */
  where?: TranslationKeyCommon;
  /** Author name. */
  author?: TranslationKeyCommon;
  /** Author role (for example, composer). */
  authorRole?: TranslationKeyCommon;
  /** Avatar image URL. */
  image?: string;
  /** Rating is either 5 or no rating. */
  rating?: 5;
};

/** Rating: always 5/5 stars */
function Rating({ rating }: { rating: 5 }) {
  const t = useTranslate();
  return (
    <div className="flex">
      <p className="sr-only">{t('campaigns__testimonials_rating', [rating])}</p>
      {Array.from({ length: 5 }).map((_, index) => (
        <FavoriteIcon key={index} width={40} height={40} className="text-campaignAccent" />
      ))}
    </div>
  );
}

/**
 * Testimonial card for a promo campaign.
 */
export function CampaignTestimonial({ rating, text, date, where, author, authorRole, image }: TestimonialProps) {
  const t = useTranslate();
  return (
    <article className="flex h-full flex-col justify-between gap-6 rounded-2xl bg-surface-700 p-10">
      <div className="space-y-8">
        {rating && <Rating rating={rating} />}
        {image && (
          <Image
            src={image}
            alt=""
            className="size-20 rounded-full"
            width={160}
            height={160}
            loading="lazy"
            loader={cloudinaryLoader}
            unoptimized
          />
        )}
        <p className="text-textPrimary typo-title-3">{t(text)}</p>
      </div>
      {(where || date || authorRole) && (
        <footer className="flex flex-col gap-6">
          {author && (
            <footer className="border-l-4 border-campaignAccent pl-3 text-textPrimary typo-title-3">{t(author)}</footer>
          )}
          <div className="space-x-1">
            {[where, date, authorRole].filter(Boolean).map((value, index) => (
              <Fragment key={value}>
                {index > 0 && (
                  <span className="text-textTertiary typo-caption-1" aria-hidden>
                    •
                  </span>
                )}
                <span className="text-textTertiary typo-caption-1">{t(value!)}</span>
              </Fragment>
            ))}
          </div>
        </footer>
      )}
    </article>
  );
}
