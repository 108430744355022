import { FaqList } from 'src/components/faq-list';
import useTranslate from 'src/hooks/use-translate';
import type { Faq } from 'src/utilities/get-faqs';

/**
 * FAQ section for promo campaigns.
 *
 * **How to use**
 *
 * 1. Fetch the fallback data in getStaticProps():
 *
 * ```ts
 * const currentLocale = getCurrentLocale(context.locale);
 * const faqs = await getFaqs({ locale: currentLocale, zendeskCategoryId: '??ID??' });
 * ```
 *
 * 2. Render the section using the prepared data:
 *
 * ```tsx
 * <CampaignFaqs faqs={faqs} />
 * ```
 */
export function CampaignFaqs({ faqs }: { faqs: Faq[] }) {
  const t = useTranslate();

  // Don’t render anything if there are no items
  if (faqs.length === 0) {
    return null;
  }

  return (
    <section className="mx-auto flex max-w-[50rem] flex-col gap-y-12">
      <h2 className="text-center text-textPrimary typo-title-1">{t('marketing_devices__faqs')}</h2>
      <FaqList faqs={faqs} />
    </section>
  );
}
