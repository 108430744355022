import type { ComponentProps } from 'react';
import clsx from 'clsx';

// @todo: Add a story

/**
 * A card with highlight at the top and rounded corners
 */
export function CampaignCard({ className, ...props }: ComponentProps<'div'>) {
  return (
    <div
      className={clsx(
        'relative bg-surface-600 p-8 before:absolute before:inset-x-0 before:top-0 before:h-px before:bg-[radial-gradient(circle_at_50%_-50%,hsl(var(--twc-campaignAccent)),hsl(var(--twc-black)/0)_90%)] md:rounded-3xl',
        className,
      )}
      {...props}
    />
  );
}
