import { type ReactNode, type MouseEventHandler } from 'react';
import BigChevronIcon from '@stageplus/icons/react/big-chevron';
import BigChevronLeftIcon from '@stageplus/icons/react/big-chevron-left';
import { Slider, SliderProvider } from 'src/components/common/slider';
import useSliderNavigation from 'src/hooks/use-slider-navigation';
import useTranslate from 'src/hooks/use-translate';

function SliderNavigationButton({
  variant,
  disabled,
  children,
  onClick,
}: {
  variant: 'previous' | 'next';
  disabled: boolean;
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  const Icon = variant === 'previous' ? BigChevronLeftIcon : BigChevronIcon;
  return (
    <button
      className={
        disabled ? 'text-surface-300' : 'text-textPrimary hover:text-textSecondary focus-visible:focus-outline'
      }
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <Icon className="size-12" aria-hidden />
      <span className="sr-only">{children}</span>
    </button>
  );
}

function SliderNavigation() {
  const t = useTranslate();
  const { canSlidePrevious, canSlideNext, slidePrevious, slideNext } = useSliderNavigation();

  return (
    <div className="-mx-2 flex gap-6">
      <SliderNavigationButton variant="previous" onClick={slidePrevious} disabled={canSlidePrevious === false}>
        {t('slider__previous')}
      </SliderNavigationButton>
      <SliderNavigationButton variant="next" onClick={slideNext} disabled={canSlideNext === false}>
        {t('slider__next')}
      </SliderNavigationButton>
    </div>
  );
}

type ContentSliderProps = {
  title?: string;
  subtitle?: string;
  buttons?: ReactNode;
  children: ReactNode;
  sliderContainerClassName?: string;
};

/**
 * A generic content slider component for promo campaigns.
 */
export function CampaignContentSlider({
  title,
  subtitle,
  buttons,
  children,
  sliderContainerClassName,
}: ContentSliderProps) {
  return (
    <SliderProvider>
      <section className="flex grid-cols-[1fr,1.5fr] flex-col gap-6 md:grid md:gap-16">
        <header className="flex flex-col gap-6 md:gap-20">
          <div className="flex flex-col gap-y-6 before:h-1 before:w-12 before:rounded-sm before:bg-campaignAccent">
            <h2 className="text-balance font-medium leading-none text-textPrimary typo-large-title">{title}</h2>
            {subtitle && <p className="text-textSecondary typo-title-2">{subtitle}</p>}
            {buttons && <div className="hidden gap-4 md:flex">{buttons}</div>}
          </div>
          <SliderNavigation />
        </header>
        <div className={`overflow-hidden ${sliderContainerClassName}`}>
          <Slider withNavigation={false}>{children}</Slider>
        </div>
        {buttons && <footer className="flex flex-col gap-4 md:hidden">{buttons}</footer>}
      </section>
    </SliderProvider>
  );
}
