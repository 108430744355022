import artistDuenasImage from 'public/images/campaigns/artist-duenas.jpg';
import artistNezetSeguinImage from 'public/images/campaigns/artist-nezet-seguin.jpg';
import artistOlafssonImage from 'public/images/campaigns/artist-olafsson.jpg';
import artistSoltaniImage from 'public/images/campaigns/artist-soltani.jpg';
import artistTetelmanImage from 'public/images/campaigns/artist-tetelman.jpg';
import artistVillazonImage from 'public/images/campaigns/artist-villazon.jpg';
import { CampaignContentSlider } from 'src/components/campaigns/campaign-content-slider';
import { CampaignTestimonial, type TestimonialProps } from 'src/components/campaigns/campaign-testimonial';
import { SliderSlide } from 'src/components/common/slider';
import useTranslate from 'src/hooks/use-translate';

const slides: TestimonialProps[] = [
  {
    text: 'campaigns__testimonials_review_1_text',
    date: 'campaigns__testimonials_review_1_date',
    where: 'campaigns__testimonials_review_1_where',
    rating: 5,
  },
  {
    text: 'campaigns__testimonials_review_2_text',
    date: 'campaigns__testimonials_review_2_date',
    where: 'campaigns__testimonials_review_2_where',
    rating: 5,
  },
  {
    text: 'campaigns__testimonials_review_3_text',
    date: 'campaigns__testimonials_review_3_date',
    where: 'campaigns__testimonials_review_3_where',
    rating: 5,
  },
  {
    text: 'campaigns__testimonials_review_4_text',
    date: 'campaigns__testimonials_review_4_date',
    where: 'campaigns__testimonials_review_4_where',
    rating: 5,
  },
  {
    text: 'campaigns__testimonials_review_5_text',
    date: 'campaigns__testimonials_review_5_date',
    where: 'campaigns__testimonials_review_5_where',
    rating: 5,
  },
  {
    text: 'campaigns__testimonials_review_6_text',
    date: 'campaigns__testimonials_review_6_date',
    where: 'campaigns__testimonials_review_6_where',
    rating: 5,
  },
  {
    // María Dueñas
    text: 'campaigns__testimonials_artist_1_text',
    author: 'campaigns__testimonials_artist_1_author',
    authorRole: 'campaigns__testimonials_artist_1_role',
    image: artistDuenasImage.src,
  },
  {
    // Kian Soltani
    text: 'campaigns__testimonials_artist_2_text',
    author: 'campaigns__testimonials_artist_2_author',
    authorRole: 'campaigns__testimonials_artist_2_role',
    image: artistSoltaniImage.src,
  },
  {
    // Víkingur Ólafsson
    text: 'campaigns__testimonials_artist_3_text',
    author: 'campaigns__testimonials_artist_3_author',
    authorRole: 'campaigns__testimonials_artist_3_role',
    image: artistOlafssonImage.src,
  },
  {
    // Jonathan Tetelman
    text: 'campaigns__testimonials_artist_4_text',
    author: 'campaigns__testimonials_artist_4_author',
    authorRole: 'campaigns__testimonials_artist_4_role',
    image: artistTetelmanImage.src,
  },
  {
    // Yannick Nézet-Séguin
    text: 'campaigns__testimonials_artist_5_text',
    author: 'campaigns__testimonials_artist_5_author',
    authorRole: 'campaigns__testimonials_artist_5_role',
    image: artistNezetSeguinImage.src,
  },
  {
    // Rolando Villazón
    text: 'campaigns__testimonials_artist_6_text',
    author: 'campaigns__testimonials_artist_6_author',
    authorRole: 'campaigns__testimonials_artist_6_role',
    image: artistVillazonImage.src,
  },
];

/**
 * A slider with predefined testimonials for promo campaigns.
 */
export function CampaignTestimonials() {
  const t = useTranslate();

  return (
    <CampaignContentSlider sliderContainerClassName="rounded-2xl" title={t('campaigns__testimonials_heading')}>
      {slides.map((item) => (
        <SliderSlide
          key={item.image ?? item.text}
          // @hack: Use ! to override width:100% and height:100% hardcoded in Swiper styles
          className="mr-8 !h-auto !w-80 shrink-0 select-none last:mr-0 md:!w-[30rem]"
        >
          <CampaignTestimonial {...item} />
        </SliderSlide>
      ))}
    </CampaignContentSlider>
  );
}
