import type { CuratedContentContainerFieldsFragment } from 'generated/graphql';
import { Slider, SliderProvider, SliderSlide } from 'src/components/common/slider';
import { NodeTeaser } from 'src/components/node/node-teaser';

/**
 * Determines if the given ContentContainer is a Slider and contains
 * LiveConcerts. Necessary because we want to make sure that we only show Live
 * Concerts using this component, and technically the API could also return
 * other types.
 */
export function isContentContainerLiveConcertSlider(
  contentContainer: CuratedContentContainerFieldsFragment,
): contentContainer is Extract<CuratedContentContainerFieldsFragment, { __typename: 'ListSlider' }> {
  return contentContainer.__typename === 'ListSlider' && contentContainer.items[0]?.__typename === 'LiveConcert';
}

/**
 * Upcoming lives slider for promo campaigns.
 *
 * **How to use**
 *
 * 1. Fetch the fallback data in getStaticProps():
 *
 * ```ts
 * // Fetch the curated content for the page, it will be used as initial data
 * // during the incremental server side rendering
 * const curatedContentFallback = await sdk.curatedContent({ slug: CuratedContentSlug.Live });
 * ```
 *
 * 2. Fetch the up-to-date data in the page component:
 *
 * ```ts
 * const { data: curatedContentData } = useCuratedContent(CuratedContentSlug.Live, curatedContentFallback);
 *
 * // Find the first Live Concert slider
 * const firstLiveConcertSlider = curatedContentData?.curatedContent.curatedContent?.find(
 *   isContentContainerLiveConcertSlider,
 * );
 * ```
 *
 * 3. Render the slider using the prepared data:
 *
 * ```tsx
 * {firstLiveConcertSlider && <CampaignLivesSlider slider={firstLiveConcertSlider} />}
 * ```
 */
export function CampaignLivesSlider({
  slider,
}: {
  slider: Extract<CuratedContentContainerFieldsFragment, { __typename: 'ListSlider' }>;
}) {
  const sliderItems = slider.items ?? [];

  // Don’t render anything if there are no items
  if (sliderItems.length === 0) {
    return null;
  }

  return (
    <SliderProvider>
      <section className="flex flex-col gap-8">
        <h2 className="typo-title-2">{slider.headline}</h2>
        <div className="overflow-hidden">
          <Slider withNavigation={false}>
            {sliderItems.map((node, index) => (
              <SliderSlide
                key={`${node.id}${index}`}
                // @hack: Use ! to override width:100% and height:100% hardcoded in Swiper styles
                className="mr-8 !h-auto !w-80 shrink-0 select-none last:mr-0 md:!w-[30rem]"
              >
                <NodeTeaser node={node} />
              </SliderSlide>
            ))}
          </Slider>
        </div>
      </section>
    </SliderProvider>
  );
}
