import { Fragment, type ComponentProps, type ReactNode } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { StageLogo } from '@stageplus/components/src/stage-logo';
import clsx from 'clsx';
import Image, { type StaticImageData } from 'next/image';
import albumsIcon from 'public/images/campaigns/features-albums.svg';
import concertsIcon from 'public/images/campaigns/features-concerts.svg';
import coversImage from 'public/images/campaigns/features-covers.avif';
import hiFiIcon from 'public/images/campaigns/features-hi-fi.svg';
import maestroImage from 'public/images/campaigns/features-maestro.avif';
import ringsImage from 'public/images/campaigns/features-rings.svg';
import streamsImage from 'public/images/campaigns/features-streams.avif';
import streamsIcon from 'public/images/campaigns/features-streams.svg';
import tvImage from 'public/images/campaigns/features-tv.avif';
import violinImage from 'public/images/campaigns/features-violin.avif';
import violinistImage from 'public/images/campaigns/features-violinist.avif';
import formats4kUltraHdImage from 'public/images/campaigns/formats-4k-ultra-hd.svg';
import formatsDolbyAtmosImage from 'public/images/campaigns/formats-dolby-atmos.svg';
import formatsLosslessImage from 'public/images/campaigns/formats-lossless.svg';
import styles from 'src/components/campaigns/campaign-tabs.module.css';
import useTranslate from 'src/hooks/use-translate';
import type { TranslationKeyCommon } from 'src/types';
import { cloudinaryLoader } from 'src/utilities/image-sdk';

// Container component for sections inside tabs
function ContentContainer({ className, ...props }: ComponentProps<'div'>) {
  return (
    <div
      className={clsx(
        'overflow-hidden rounded-3xl border-2 border-solid border-surface-500 bg-surface-600 text-textPrimary',
        className,
      )}
      {...props}
    />
  );
}

const features: {
  image: StaticImageData;
  width: number;
  height: number;
  caption: TranslationKeyCommon;
  // Image used in the panel that should be preloaded on hover over the tab button
  assets: { image: StaticImageData; width: number }[];
  // Component that renders tab panel content
  Panel: () => ReactNode;
}[] = [
  {
    image: concertsIcon as StaticImageData,
    width: 24,
    height: 25,
    caption: 'campaigns__features_1',
    assets: [],
    Panel() {
      return (
        <Image
          src={tvImage}
          width={634}
          height={462}
          alt=""
          loading="lazy"
          className="mx-auto"
          style={{
            filter: 'drop-shadow(0 -1rem 3rem #00000033)',
          }}
          loader={cloudinaryLoader}
          unoptimized
        />
      );
    },
  },
  {
    image: streamsIcon as StaticImageData,
    width: 36,
    height: 36,
    caption: 'campaigns__features_2',
    assets: [{ image: streamsImage, width: 640 }],
    Panel() {
      return (
        <Image
          src={streamsImage}
          width={640}
          height={464}
          alt=""
          className="mx-auto rounded-3xl"
          style={{
            filter: 'drop-shadow(0 0 3rem #00000011)',
          }}
          loader={cloudinaryLoader}
          unoptimized
        />
      );
    },
  },
  {
    image: hiFiIcon as StaticImageData,
    width: 34,
    height: 13,
    caption: 'campaigns__features_3',
    assets: [{ image: maestroImage, width: 313 }],
    Panel() {
      const t = useTranslate();
      return (
        <div className="mx-auto grid max-w-[400px] gap-8 md:max-w-[900px] md:grid-cols-[auto_400px] md:flex-row">
          <ContentContainer className="flex flex-col justify-between gap-2 px-4 pb-4 pt-6">
            <div className="flex flex-col items-center gap-4">
              <Image
                className=""
                src={formatsDolbyAtmosImage as StaticImageData}
                alt="Dolby Atmos"
                width={86}
                height={35}
                unoptimized
              />
              <p className="max-w-60 text-center text-textTertiary typo-caption-1">{t('campaigns__features_atmos')}</p>
            </div>
            <div
              className="bg-no-repeat py-12 md:px-8"
              style={{
                // Repeat the same image four times
                backgroundImage: Array.from({ length: 4 })
                  .map(() => `url('${(ringsImage as StaticImageData).src}')`)
                  .join(', '),
                backgroundPosition: 'center top, center bottom, left center, right center',
                backgroundSize: '160px',
              }}
            >
              <Image
                src={violinistImage}
                alt=""
                width={382}
                height={214}
                style={{ filter: 'drop-shadow(0 0 4rem #00000033)' }}
                loader={cloudinaryLoader}
                unoptimized
              />
            </div>
          </ContentContainer>
          <div className="flex max-w-[400px] flex-col gap-8">
            <ContentContainer className="flex flex-col items-center px-4 md:px-20">
              <div
                className="mt-[-2px] flex h-[90px] w-[230px] items-center justify-center bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url('${(ringsImage as StaticImageData).src}')` }}
              >
                <Image
                  className=""
                  src={formats4kUltraHdImage as StaticImageData}
                  alt="4K UltraHD"
                  width={44}
                  height={29}
                  unoptimized
                />
              </div>
              <div
                className="mb-[-2px] mt-[-30px] md:mt-[-24px]"
                style={{
                  filter: 'drop-shadow(0 -0.5rem 2.5rem #00000033)',
                }}
              >
                <Image src={maestroImage} alt="" width={313} height={172} loader={cloudinaryLoader} unoptimized />
              </div>
            </ContentContainer>
            <ContentContainer
              className="flex flex-col items-center gap-1 text-clip pt-6"
              style={{ overflowClipMargin: 2 }}
            >
              <div className="flex flex-col items-center gap-4">
                <Image
                  className=""
                  src={formatsLosslessImage as StaticImageData}
                  alt="Lossless audio"
                  width={77}
                  height={28}
                  unoptimized
                />
                <p className="max-w-60 text-center text-textTertiary typo-caption-1">
                  {t('campaigns__features_lossless')}
                </p>
              </div>
              <div className="w-full">
                <div
                  className="mx-[-2px] mb-[-2px] h-[102px] overflow-hidden bg-contain bg-[center_bottom] bg-no-repeat pt-6"
                  style={{ backgroundImage: `url('${violinImage.src}')` }}
                >
                  <Image
                    className="h-[280px] w-full opacity-30"
                    src={ringsImage as StaticImageData}
                    alt=""
                    unoptimized
                  />
                </div>
              </div>
            </ContentContainer>
          </div>
        </div>
      );
    },
  },
  {
    image: albumsIcon as StaticImageData,
    width: 47,
    height: 16,
    caption: 'campaigns__features_4',
    assets: [{ image: coversImage, width: 636 }],
    Panel() {
      const t = useTranslate();
      return (
        <ContentContainer
          className="mx-auto flex max-w-screen-sm flex-col items-center gap-12 text-clip"
          style={{ overflowClipMargin: 2 }}
        >
          <Image
            className="w-full"
            src={coversImage}
            alt=""
            width={636}
            height={221}
            loader={cloudinaryLoader}
            unoptimized
          />
          <p className="max-w-80 text-center text-textTertiary typo-caption-1">{t('campaigns__features_albums')}</p>
          <div className="w-full">
            <div className="mb-[-2px] h-[102px] overflow-hidden">
              <Image
                className="h-[300px] w-full"
                src={ringsImage as StaticImageData}
                alt=""
                width={636}
                height={221}
                unoptimized
              />
            </div>
          </div>
        </ContentContainer>
      );
    },
  },
];

/**
 * Tabs block with features for promo campaigns.
 */
export function CampaignTabs() {
  const t = useTranslate();

  return (
    <TabGroup>
      <div className="-mx-5 flex flex-col items-center gap-y-10 text-balance bg-surface-700 px-5 py-14 pb-40 text-center text-textPrimary md:mx-auto md:rounded-4xl md:px-8">
        <div className="flex max-w-[50rem] flex-col items-center gap-y-5">
          <StageLogo alt="" className="h-5 lg:h-8" />
          <h2 className="typo-large-title">{t('campaigns__features_heading')}</h2>
          <p className="text-textTertiary typo-title-3">{t('campaigns__features_subheading')}</p>
        </div>
        <TabList className="grid grid-cols-2 gap-5 md:flex">
          {features.map(({ image, width, height, caption, assets }) => (
            <Tab as={Fragment} key={image.src}>
              {({ hover, selected, focus }) => (
                <button className="group flex max-w-40 flex-col items-center gap-3 outline-none">
                  <div
                    className={clsx(
                      'flex h-12 w-20 items-center justify-center rounded-full transition-colors duration-75 ease-out',
                      !hover && !selected && 'bg-pageBackground',
                      hover && !selected && 'bg-surface-400',
                      selected && !focus && 'bg-brand-dg outline outline-2 outline-pageBackground',
                      focus && 'bg-brand-dg outline outline-4 outline-focusOutline',
                    )}
                  >
                    <div
                      className={clsx(
                        'size-full [mask-position:center] [mask-repeat:no-repeat]',
                        selected || focus ? 'bg-black' : 'bg-textPrimary',
                      )}
                      style={{
                        maskImage: `url(${image.src})`,
                        maskSize: `${width}px ${height}px`,
                      }}
                    ></div>
                  </div>
                  <span className="typo-caption-1">{t(caption)}</span>
                  {/* Preload images used in the tab panel */}
                  {hover &&
                    assets.map(({ image, width }) => (
                      <link
                        key={image.src}
                        rel="preload"
                        as="image"
                        href={cloudinaryLoader({ src: image.src, width })}
                      />
                    ))}
                </button>
              )}
            </Tab>
          ))}
        </TabList>
      </div>
      <TabPanels className="-mt-24 md:min-h-[475px]">
        {features.map(({ image, Panel }) => (
          <TabPanel key={image.src}>
            <div className={styles.panel}>
              <Panel />
            </div>
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}
