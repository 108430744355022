import type { StaticImageData } from 'next/image';
import logoAppleAndroidTv from 'public/images/devices/logo-android-tv.svg';
import logoAppleIpad from 'public/images/devices/logo-apple-ipad.svg';
import logoAppleIphone from 'public/images/devices/logo-apple-iphone.svg';
import logoAppleTv from 'public/images/devices/logo-apple-tv.svg';
import logoAppleVisionPro from 'public/images/devices/logo-apple-vision-pro.svg';
import logoFireTv from 'public/images/devices/logo-fire-tv.svg';

const devices: { image: StaticImageData; width: number; height: number; caption: string }[] = [
  {
    image: logoAppleTv as StaticImageData,
    width: 61,
    height: 60,
    caption: 'Apple TV',
  },
  {
    image: logoAppleIphone as StaticImageData,
    width: 104,
    height: 60,
    caption: 'Apple iPhone',
  },
  {
    image: logoAppleIpad as StaticImageData,
    width: 63,
    height: 60,
    caption: 'Apple iPad',
  },
  {
    image: logoAppleVisionPro as StaticImageData,
    width: 174,
    height: 60,
    caption: 'Apple Vision Pro',
  },
  {
    image: logoAppleAndroidTv as StaticImageData,
    width: 61,
    height: 60,
    caption: 'Android TV',
  },
  {
    image: logoFireTv as StaticImageData,
    width: 61,
    height: 60,
    caption: 'Fire TV',
  },
];

/**
 * Section with logos of devices where the Stage+ app is available.
 */
export function CampaignDevices() {
  return (
    <div className="flex flex-wrap items-center justify-center gap-x-1 gap-y-2 md:gap-x-14">
      {devices.map(({ image, width, height, caption }) => (
        <img
          key={image.src}
          src={image.src}
          width={width}
          height={height}
          alt={caption}
          loading="lazy"
          className="h-10 md:h-auto"
        />
      ))}
    </div>
  );
}
