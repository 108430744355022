import { type ReactNode } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';

// @todo: Add a story

/**
 * List of features for promo campaigns.
 */
export function CampaignFeatureList({ children }: { children: ReactNode }) {
  return <ul className="space-y-2">{children}</ul>;
}

/**
 * List of features item for promo campaigns.
 */
export function CampaignFeatureListItem({ children }: { children: ReactNode }) {
  return (
    <li className="flex items-center space-x-3 text-textSecondary typo-body-1">
      <span className="block size-[18px] shrink-0" aria-hidden>
        <CheckmarkIcon className="size-full rounded-full bg-campaignAccent text-buttonForeground" />
      </span>
      <span suppressHydrationWarning>{children}</span>
    </li>
  );
}
