import shelfImage from 'public/images/christmas2024/shelf.avif';
import { TextButtonLink } from 'src/components/buttons/text-button';
import { CampaignCard } from 'src/components/campaigns/campaign-card';
import { CampaignFeatureList, CampaignFeatureListItem } from 'src/components/campaigns/campaign-feature-list';
import { GiftCardsBadge } from 'src/components/gift-cards/gift-cards-badge';
import useTranslate from 'src/hooks/use-translate';

function SecondarySignupBody() {
  const t = useTranslate();

  return (
    <CampaignCard className="flex w-full flex-col space-y-6 md:w-auto md:max-w-lg">
      <div className="space-y-4">
        <header className="space-y-2">
          <h2 className="mx-auto flex flex-col gap-5 text-balance">
            <span className="block uppercase tracking-[0.375rem] text-campaignAccent typo-caption-1">
              <GiftCardsBadge>{t('gift_cards__pretitle')}</GiftCardsBadge>
            </span>
            <span className="block typo-title-2">{t('gift_cards__singup_title')}</span>
          </h2>
        </header>
        <CampaignFeatureList>
          <CampaignFeatureListItem>{t('gift_cards__signup_feature_1')}</CampaignFeatureListItem>
          <CampaignFeatureListItem>{t('gift_cards__signup_feature_2')}</CampaignFeatureListItem>
          <CampaignFeatureListItem>{t('gift_cards__signup_feature_3')}</CampaignFeatureListItem>
        </CampaignFeatureList>
      </div>
      <div className="flex flex-col gap-2 md:flex-row">
        <TextButtonLink data-test="signup-cta-button" variation="secondary-new" fullWidth href="#purchase">
          {t('gift_cards__signup_options_button_label')}
        </TextButtonLink>
      </div>
    </CampaignCard>
  );
}

/**
 * Gift cards page secondary signup block
 */
export function GiftCardsSecondarySignup() {
  return (
    <div className="relative -mx-5 flex items-center justify-center overflow-hidden bg-surface-800 md:mx-0 md:rounded-3xl md:px-16 md:py-28 lg:py-36 xl:justify-end">
      <div
        className="absolute inset-0 hidden bg-cover bg-center bg-no-repeat xl:block"
        style={{ backgroundImage: `url('${shelfImage.src}')` }}
      ></div>
      <SecondarySignupBody />
    </div>
  );
}
